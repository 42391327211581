/*
 * @Author: 魏鑫
 * @Date: 2020-01-09 15:18:19
 * @LastEditors  : 李波
 * @LastEditTime : 2020-01-13 11:21:32
 */
import axios from '@/lib/api.request'

// 预收报表数据
export const advance = data => axios.post('/hx/reportForm/advance', data)
